import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "createscroomoptions"
    }}>{`CreateScroomOptions`}</h2>
    <p>{`The options you provided when creating scroom instance.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`export interface CreateScroomOptions<T extends Element> {
  /** target element */
  target: T;
  /** trigger distance from the top/left of the viewport. unit: %. range: 0 - 1. default: 0.5 */
  offset?: number;
  /** scrolling detection frequency threshold. unit: px. default: 4 */
  threshold?: number;
  /** scrolling detection direction. default: vertical */
  direction?: "vertical" | "horizontal";
}
`}</code></pre>
    <br />
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <p>{`Props of scroom instance.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`/** scroom instance */
export interface ScroomInstance<T extends Element> {
  target: T;
  /** intersection observer inside this instance */
  observer: IntersectionObserver;
  /** add event listener */
  on: Emittery<ScroomEventMap<T>>["on"];
  /** add event listener for once */
  once: Emittery<ScroomEventMap<T>>["once"];
  /** remove event listener */
  off: Emittery<ScroomEventMap<T>>["off"];
  /** destroy instance and remove all event listeners */
  destroy: () => void;
}
`}</code></pre>
    <h2 {...{
      "id": "events"
    }}>{`Events`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`sc.on("enter", (data) => void);
`}</code></pre>
    <p>{`Callback that fires when the edge of a step element enters the offset threshold.`}</p>
    <p><strong parentName="p">{`Params`}</strong>{`:`}</p>
    <ul>
      <li parentName="ul">{`target: Target Element`}</li>
    </ul>
    <br />
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`sc.on("leave", (data) => void);
`}</code></pre>
    <p>{`Callback that fires when edge of a step element leaves the offset threshold.`}</p>
    <p><strong parentName="p">{`Params`}</strong>{`:`}</p>
    <ul>
      <li parentName="ul">{`target: Target Element`}</li>
    </ul>
    <br />
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`sc.on("progress", (data) => void);
`}</code></pre>
    <p>{`Callback that fires the progress (0 - 1) a step has made through the threshold.`}</p>
    <p><strong parentName="p">{`Params`}</strong>{`:`}</p>
    <ul>
      <li parentName="ul">{`target: Target Element`}</li>
      <li parentName="ul">{`progress: The percent of completion of the step (0 - 1)`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      